import { PropsWithChildren } from "react";

interface Props {
  busy: boolean;
}

export const Busy: React.FC<PropsWithChildren<Props>> = ({
  busy,
  children,
}) => {
  const className = busy ? "busy-active" : "";

  return <div className={`busy ${className}`}>{children}</div>;
};
